/* eslint-disable */

'use strict';

/*-------------------------------------------------
    Source of this template in root directory
    environment/config.template.js
    this is only example which you may define any structure
---------------------------------------------------*/
/**
 * app configuration from environment
 * @typedef {Object} config
 * @property {Number}       timestamp
 * @property {String}       version
 * @property {Boolean}      production
 * @property {String}       baseUrl
 * @property {String}       apiPath
 * @property {String}       serviceUrl
 * @property {String}       websiteUrl
 */
export default {
    "timestamp": 1728732435528,
    "version": "2.0.1",
    "DEBUG": false,
    "production": false,
    "clientTimeFormat": "D MMMM YYYY",
    "serviceUrl": "https://qa-api.app.risk-q.com",
    "websiteUrl": "https://qa.app.risk-q.com",
    "apiPath": "/api",
    "base": "com.vrisk:21827392bacff",
    "GOOGLE_CLIENT_ID": "70875112487-c3mfg6jejui45vb66m6nvaed79mrib1e.apps.googleusercontent.com",
    "MICROSOFT_CLIENT_ID": "4af250c7-965d-4b0b-aafa-3bfbcb4213d5"
}
